import { useState, useEffect } from 'react';

export const ImageGen = ({ typePopup, variables, picsCount, filmsCount, handleClick }) => {

    // Zapisuje w tablicy wylosowane zdjęcia
    const [pics, setPics] = useState([]);
    // Zapisuje w tablicy wylosowane filmy
    const [films, setFilms] = useState([]);
    // Aktualna ilość zdjęć w folderze
    const allPics = 35;
    // Aktualna ilość filmów w folderze
    const allFilms = 20;
    // Zmienna do wyświetlania ładowania w zdjęciach i filmach
    const [loading, setLoading] = useState(false);
    

    const generateUniqueRandomNumbers = (count, all) => {
        const numbers = [];
        while (numbers.length < count) {
            const newNumber = Math.floor(Math.random() * all) + 1;
            if (!numbers.includes(newNumber)) {
                numbers.push(newNumber);
            }
        }
        return numbers;
    };

    useEffect(() => {
        const uniqueNumbersPics = generateUniqueRandomNumbers(picsCount, allPics);
        const uniqueNumbersFilms = generateUniqueRandomNumbers(filmsCount, allFilms);
        setPics(uniqueNumbersPics);
        setFilms(uniqueNumbersFilms);
    }, []);

    const handleItemClick = (type, number) => {
        // Ustaw stan ładowania dla konkretnego elementu
        setLoading((prev) => ({ ...prev, [number]: true }));
        handleClick(type, () => {
          // Wyłącz stan ładowania po zakończeniu operacji
          setLoading((prev) => ({ ...prev, [number]: false }));
        });
      };
    
      return (
        <>
          {typePopup === 'films' ?
            films.map((number) => (
              <div key={number} className='films'>
                <div className='player'>
                    {!loading[number] ? (
                        <img onClick={() => handleItemClick('films', number)} src='/img/player.png' alt='Player'/>
                    ) : (
                        <div className='loader'></div>
                    )}
                </div>
                <img src={`/img/films/${number}.jpg`} alt={`Image ${number}`} style={{ height: !loading[number] ? '280px' : '300px'}}/>
                <img onClick={() => handleItemClick('films', number)} style={{ display: !loading[number] ? 'flex' : 'none'}} className='sidebar' src='/img/player_details.png' alt='Player details'/>
              </div>
            )) :
            pics.map((number) => (
              <div key={number} className='pics'>
                <div className='lock'>
                  {!loading[number] ? (
                    <img onClick={() => handleItemClick('pics', number)} style={{ marginTop: '-50px' }} src='/img/lock.png' alt='Lock' />
                  ) : (
                    <div className='loader'></div>
                  )}
                </div>
                <div onClick={() => handleItemClick('pics', number)} className='btn' style={{ display: !loading[number] ? 'flex' : 'none', position: 'absolute', marginTop: '50px' }}>
                  {variables.unlockImage}
                </div>
                <img src={`/img/pics/${number}.jpg`} alt={`Image ${number}`} />
              </div>
            ))
          }
        </>
      );
    };