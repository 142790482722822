import { useState, useEffect } from 'react';

export const PopUp = ({variables, typePopup, link}) => {

    // Zmienna do wyświetlania loadera
    const [loader, setLoader] = useState(false);
    // Ustawianie aktualnego tekstu
    const [text, setText] = useState(variables.loaderText1);
    // Zapisywanie ilości powtórzenia pętli
    const [count, setCount] = useState(1);

    useEffect(() => {
        if (count < 6) {
            const timer = setTimeout(() => {
                setText(variables[`loaderText${count}`]); // Dynamiczne tworzenie tekstu
                setCount(count + 1);
            }, 2000);

            // Czyszczenie efektu po unmount lub przed kolejnym wywołaniem
            return () => clearTimeout(timer);
        } else setLoader(false);

    }, [count]);

    useEffect(() => {
        typePopup === 'message' ? setLoader(true) : setLoader(false);
    }, [typePopup]);

    return (
        <>  
        {loader ?
            <div className='container' style={{marginTop: '20vh'}}>
                <div className='loader'></div>
                <div className='title color-natural'>{text}</div>
            </div> : typePopup === 'message' & !loader ?
            <div className='popup'>
                <div className='container' style={{marginTop: '20vh'}}>
                    <div className='title color-orange'>{variables.titlePopupMessage}</div>
                    <div className='subtitle'>{variables.subtitlePopupMessage}</div>
                    <div className='c-btn'>
                        <button onClick={() => window.location.href=link} className='btn pulse' style={{backgroundColor: "#5975ff"}}>{variables.buttonPopup}</button>
                    </div>
                </div>
            </div> : typePopup === 'pics' || typePopup === 'films' ?
            <div className='popup'>
                <div className='container' style={{marginTop: '20vh'}}>
                    <div className='title color-orange'>{variables.titlePopup}</div>
                    <div className='subtitle'>{typePopup === 'pics' ? variables.subtitlePopupPics : variables.subtitlePopupfilms}</div>
                    <div className='c-btn'>
                        <button onClick={() => window.location.href=link} className='btn pulse' style={{backgroundColor: "#5975ff"}}>{variables.buttonPopup}</button>
                    </div>
                </div>
            </div> : typePopup === 'login' ?
            <div className='popup'>
                <div className='container' style={{marginTop: '20vh'}}>
                    <div className='title color-orange'>{variables.titlePopup}</div>
                    <div className='subtitle'>{variables.subtitlePopup}</div>
                    <div className='c-btn'>
                        <button onClick={() => window.location.href=link} className='btn pulse' style={{backgroundColor: "#5975ff"}}>{variables.buttonPopup}</button>
                    </div>
                </div>
            </div> : null
        }
        </>
    );
}