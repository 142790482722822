export const getLanguage = (language, setVariables, afterSlash) => {

    switch (language) {
      case 'pl-PL':
        setVariables({
            titlePopup: 'Niestety nie jesteś tutaj zarejestrowany',
            subtitlePopup: 'Musisz utworzyć darmowe konto, abyśmy mogli znaleźć jej dane. Pamiętaj, aby potwierdzić swój adres e-mail, to ważne!',
            subtitlePopupPics: 'Musisz utworzyć darmowe konto, abyśmy mogli odblokować to zdjęcie. Pamiętaj, aby potwierdzić swój adres e-mail, to ważne!',
            subtitlePopupfilms: 'Musisz utworzyć darmowe konto, abyśmy mogli odblokować ten film. Pamiętaj, aby potwierdzić swój adres e-mail, to ważne!',
            titlePopupMessage: 'Błąd podczas łączenia😒...',
            subtitlePopupMessage: 'Musisz utworzyć darmowe konto, abyśmy mogli połączyć cię z użytkownikiem. Pamiętaj, aby potwierdzić swój adres e-mail, to ważne!',
            title: 'Nie masz dostępu do tego profilu!',
            subtitle: 'Kliknij poniższy przycisk i zaloguj się aby odblokować dostęp 👇',
            buttonPopup: 'Zarejestruj się TERAZ!',
            login: 'Zaloguj się',
            unlockImage: 'Odblokuj zdjęcie',
            pics: 'Zdjęcia',
            films: 'Filmy',
            online: 'Aktywna teraz',
            contact: 'Skontaktuj się przez:',
            loaderText1: 'Ładowanie...',
            loaderText2: 'Sprawdzanie czy użytkownik jest dostępny teraz...',
            loaderText3: `Przygotowanie do połączenia z użytkownikiem ${afterSlash}...`,
            loaderText4: 'Łączenie...',
            titleSite: `${afterSlash} jest teraz dostępna`,
        });
        break;
  
      case 'no-NO':
        setVariables({
          titlePopup: 'Dessverre er du ikke registrert her',
          subtitlePopup: 'Du må opprette en gratis konto slik at vi kan finne hennes opplysninger. Husk å bekrefte e-postadressen din, det er viktig!',
          subtitlePopupPics: 'Du må opprette en gratis konto slik at vi kan låse opp dette bildet. Husk å bekrefte e-postadressen din, det er viktig!',
          subtitlePopupfilms: 'Du må opprette en gratis konto slik at vi kan låse opp denne videoen. Husk å bekrefte e-postadressen din, det er viktig!',
          titlePopupMessage: 'Tilkoblingsfeil😒...',
          subtitlePopupMessage: 'Du må opprette en gratis konto slik at vi kan koble deg med brukeren. Husk å bekrefte e-postadressen din, det er viktig!',
          title: 'Du har ikke tilgang til denne profilen!',
          subtitle: 'Klikk på knappen nedenfor og logg inn for å låse opp tilgang 👇',
          buttonPopup: 'Registrer deg NÅ!',
          login: 'Logg inn',
          unlockImage: 'Lås opp bilde',
          pics: 'Bilder',
          films: 'Videoer',
          online: 'Aktiv nå',
          contact: 'Kontakt via:',
          loaderText1: 'Laster...',
          loaderText2: 'Sjekker om brukeren er tilgjengelig nå...',
          loaderText3: `Forbereder tilkobling med brukeren ${afterSlash}...`,
          loaderText4: 'Kobler til...',
          titleSite: `${afterSlash} er nå tilgjengelig`,
        });
        break;
        
      case 'en-GB':
      case 'en-CA':
      case 'en-AU':
      case 'en-US':
      default:
        setVariables({
          titlePopup: 'Unfortunately, you are not registered here',
          subtitlePopup: `You need to create a free account so we can find her details. Remember to confirm your email address, it's important!`,
          subtitlePopupPics: `You need to create a free account so we can unlock this photo. Remember to confirm your email address, it's important!`,
          subtitlePopupfilms: `You need to create a free account so we can unlock this video. Remember to confirm your email address, it's important!`,
          titlePopupMessage: 'Connection error😒...',
          subtitlePopupMessage: `You need to create a free account so we can connect you with the user. Remember to confirm your email address, it's important!`,
          title: 'You do not have access to this profile!',
          subtitle: 'Click the button below and log in to unlock access 👇',
          buttonPopup: 'Register NOW!',
          login: 'Log in',
          unlockImage: 'Unlock photo',
          pics: 'Photos',
          films: 'Videos',
          online: 'Active now',
          contact: 'Contact via:',
          loaderText1: 'Loading...',
          loaderText2: 'Checking if the user is available now...',
          loaderText3: `Preparing to connect with the user ${afterSlash}...`,
          loaderText4: 'Connecting...',
          titleSite: `${afterSlash} is now available`,
        });
        break;
  
      case 'de-DE':
        setVariables({
          titlePopup: 'Leider bist du hier nicht registriert',
          subtitlePopup: 'Du musst ein kostenloses Konto erstellen, damit wir ihre Daten finden können. Denk daran, deine E-Mail-Adresse zu bestätigen, das ist wichtig!',
          subtitlePopupPics: 'Du musst ein kostenloses Konto erstellen, damit wir dieses Foto freischalten können. Denk daran, deine E-Mail-Adresse zu bestätigen, das ist wichtig!',
          subtitlePopupfilms: 'Du musst ein kostenloses Konto erstellen, damit wir dieses Video freischalten können. Denk daran, deine E-Mail-Adresse zu bestätigen, das ist wichtig!',
          titlePopupMessage: 'Verbindungsfehler😒...',
          subtitlePopupMessage: 'Du musst ein kostenloses Konto erstellen, damit wir dich mit dem Benutzer verbinden können. Denk daran, deine E-Mail-Adresse zu bestätigen, das ist wichtig!',
          title: 'Du hast keinen Zugriff auf dieses Profil!',
          subtitle: 'Klicke auf die Schaltfläche unten und melde dich an, um den Zugriff zu entsperren 👇',
          buttonPopup: 'Jetzt registrieren!',
          login: 'Einloggen',
          unlockImage: 'Foto entsperren',
          pics: 'Fotos',
          films: 'Videos',
          online: 'Jetzt aktiv',
          contact: 'Kontaktieren über:',
          loaderText1: 'Wird geladen...',
          loaderText2: 'Überprüfung, ob der Benutzer jetzt verfügbar ist...',
          loaderText3: `Vorbereitung zur Verbindung mit dem Benutzer ${afterSlash}...`,
          loaderText4: 'Verbinden...',
          titleSite: `${afterSlash} ist jetzt verfügbar`,
        });
        break;
  
      case 'sv-SE':
        setVariables({
          titlePopup: 'Tyvärr är du inte registrerad här',
          subtitlePopup: 'Du måste skapa ett gratis konto så att vi kan hitta hennes uppgifter. Kom ihåg att bekräfta din e-postadress, det är viktigt!',
          subtitlePopupPics: 'Du måste skapa ett gratis konto så att vi kan låsa upp detta foto. Kom ihåg att bekräfta din e-postadress, det är viktigt!',
          subtitlePopupfilms: 'Du måste skapa ett gratis konto så att vi kan låsa upp denna video. Kom ihåg att bekräfta din e-postadress, det är viktigt!',
          titlePopupMessage: 'Anslutningsfel😒...',
          subtitlePopupMessage: 'Du måste skapa ett gratis konto så att vi kan ansluta dig med användaren. Kom ihåg att bekräfta din e-postadress, det är viktigt!',
          title: 'Du har inte tillgång till denna profil!',
          subtitle: 'Klicka på knappen nedan och logga in för att låsa upp tillgången 👇',
          buttonPopup: 'Registrera dig NU!',
          login: 'Logga in',
          unlockImage: 'Lås upp foto',
          pics: 'Foton',
          films: 'Videor',
          online: 'Aktiv nu',
          contact: 'Kontakta via:',
          loaderText1: 'Laddar...',
          loaderText2: 'Kontrollerar om användaren är tillgänglig nu...',
          loaderText3: `Förbereder för att ansluta med användaren ${afterSlash}...`,
          loaderText4: 'Ansluter...',
          titleSite: `${afterSlash} är nu tillgänglig`,
        });
        break;
  
      case 'it-IT':
        setVariables({
          titlePopup: 'Purtroppo non sei registrato qui',
          subtitlePopup: 'Devi creare un account gratuito affinché possiamo trovare i suoi dati. Ricorda di confermare il tuo indirizzo email, è importante!',
          subtitlePopupPics: 'Devi creare un account gratuito affinché possiamo sbloccare questa foto. Ricorda di confermare il tuo indirizzo email, è importante!',
          subtitlePopupfilms: 'Devi creare un account gratuito affinché possiamo sbloccare questo video. Ricorda di confermare il tuo indirizzo email, è importante!',
          titlePopupMessage: 'Errore di connessione😒...',
          subtitlePopupMessage: `Devi creare un account gratuito affinché possiamo collegarti con l'utente. Ricorda di confermare il tuo indirizzo email, è importante!`,
          title: 'Non hai accesso a questo profilo!',
          subtitle: `Clicca il pulsante qui sotto e accedi per sbloccare l'accesso 👇`,
          buttonPopup: 'Registrati ORA!',
          login: 'Accedi',
          unlockImage: 'Sblocca foto',
          pics: 'Foto',
          films: 'Video',
          online: 'Attiva ora',
          contact: 'Contatta tramite:',
          loaderText1: 'Caricamento...',
          loaderText2: `Verifica se l'utente è disponibile ora...`,
          loaderText3: `Preparazione per collegarsi con l'utente ${afterSlash}...`,
          loaderText4: 'Connessione in corso...',
          titleSite: `${afterSlash} è ora disponibile`,
        });
        break;
  
      case 'es-ES':
      case 'es-MX':
        setVariables({
          titlePopup: 'Desafortunadamente, no estás registrado aquí',
          subtitlePopup: 'Necesitas crear una cuenta gratuita para que podamos encontrar sus datos. ¡Recuerda confirmar tu dirección de correo electrónico, es importante!',
          subtitlePopupPics: 'Necesitas crear una cuenta gratuita para que podamos desbloquear esta foto. ¡Recuerda confirmar tu dirección de correo electrónico, es importante!',
          subtitlePopupfilms: 'Necesitas crear una cuenta gratuita para que podamos desbloquear este video. ¡Recuerda confirmar tu dirección de correo electrónico, es importante!',
          titlePopupMessage: 'Error de conexión😒...',
          subtitlePopupMessage: 'Necesitas crear una cuenta gratuita para que podamos conectarte con el usuario. ¡Recuerda confirmar tu dirección de correo electrónico, es importante!',
          title: '¡No tienes acceso a este perfil!',
          subtitle: 'Haz clic en el botón de abajo e inicia sesión para desbloquear el acceso 👇',
          buttonPopup: '¡Regístrate AHORA!',
          login: 'Iniciar sesión',
          unlockImage: 'Desbloquear foto',
          pics: 'Fotos',
          films: 'Videos',
          online: 'Activa ahora',
          contact: 'Contacta a través de:',
          loaderText1: 'Cargando...',
          loaderText2: 'Comprobando si el usuario está disponible ahora...',
          loaderText3: `Preparando para conectar con el usuario ${afterSlash}...`,
          loaderText4: 'Conectando...',
          titleSite: `${afterSlash} está ahora disponible`,
        });
        break;
    }
  };