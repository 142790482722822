import React, { useState, useEffect } from 'react';
import './App.css';
import { getLink } from './Links'
import { getLanguage } from './Language';
import { ImageGen } from './Image';
import { PopUp } from './Popup';
import ReactGA from "react-ga4";

const App = () => {

  // Google Analytics
  const TRACKING_ID = "G-13WXXZ2FC8";
  // Początkowy stan: "pics"
  const [picsCount, setPicsCount] = useState(null);
  // Zmienna do zapisania wylosowanej ilości filmów
  const [filmsCount, setFilmsCount] = useState(null);
  // Zmienna do zapisywania języka z przeglądarki
  const [language, setLanguage] = useState(null);
  // Zmienna do zapisywania języka dla fanpage
  const [variables, setVariables] = useState({});
  // Zmienna do zapisywania nazwy po slash z domeny
  const [afterSlash, setAfterSlash] = useState(null);
  // Zmienna do zapisywanie linku zależnie od miejsca publikacji
  const [link, setLink] = useState(null);
  // Zmienna do zapisywania kraju
  const [country, setCountry] = useState(null);
  // Zmienna do śledzenia inicjalizacji, ładwoanie asynchroniczne
  const [isInitialized, setIsInitialized] = useState(false);
  // Zmienna do przypisywania istnienia zdjęcia kobiety (okno profilowe)
  const [imageExists, setImageExists] = useState(false);
  // Zmienna do przypisywania typu popup
  const [typePopup, setTypePopup] = useState(null);
  // Zmienna do wyświetlania popup
  const [popup, setPopup] = useState(false);

  // Wywołuje zapytanie do API geolokalizacji IP przy pierwszym renderowaniu komponentu, aktualizując stan kraju na podstawie odpowiedzi.
  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country_name);
      })
      .catch(error => console.error('Error fetching IP geolocation data:', error));
  }, []);

  // Ustawianie nazwy po "/"
  useEffect(() => {
    // Pobieranie aktualnego adresu URL
    const url = window.location.href;
    // Wyodrębnianie nazwy po ostatnim znaku "/"
    setAfterSlash(url.substring(url.lastIndexOf('/') + 1));
    if (afterSlash === '' || afterSlash === null) {
      setAfterSlash('Anna');
    }
  }, []);

  // Ustawianie języka na podstawie preferencji językowych przeglądarki przy pierwszym renderowaniu komponentu.
  useEffect(() => {
    const browserLanguage = navigator.language;
    setLanguage(browserLanguage);
  }, []);

  // Wywołuje funkcję getLanguage z aktualną wartością language za każdym razem, gdy wartość language ulegnie zmianie.
  useEffect(() => {
    if (language && country && afterSlash !== null) {
      // Przekazywanie zmiennych
      getLanguage(language, setVariables, afterSlash);
      getLink(country, afterSlash, setLink);

      // Losowanie cyfry dla pics
      setPicsCount(Math.floor(Math.random() * 4) + 8);
      // Losowanie cyfry dla films
      setFilmsCount(Math.floor(Math.random() * 4) + 6);
      // Ładowanie asynchronicze
      setIsInitialized(true);

      // Sprawdzanie czy istnieje obraz kobiety w folderze 'women'
      const img = new Image();
      img.src = '/img/women/' + afterSlash + '.jpg';
      img.onload = () => setImageExists(true);
      img.onerror = () => setImageExists(false);

    }
  }, [language, country, afterSlash]);

  // Aktualizacja tytułu strony
  useEffect(() => {
    if (!language || !variables.titleSite) {
      document.title = ''; // Możesz ustawić tutaj inny tytuł tymczasowy
    } else {
      document.title = variables.titleSite;
      ReactGA.initialize(TRACKING_ID);
    }
  }, [language, variables.titleSite]);

  const handleClick = (type) => {
    const popupTypes = ['message', 'login', 'pics', 'films'];
    if (popupTypes.includes(type)) {
      if (type === 'pics' || type === 'films') {
        const timer = setTimeout(() => {
          setTypePopup(type);
          setPopup(true);
        }, 2000);
        
        return () => clearTimeout(timer);
      } else {
        setTypePopup(type);
        setPopup(true);
      }
    }
  };

  // Renderuj stronę tylko po zainicjalizowaniu
  if (!isInitialized) {
    return <div></div>;
  }
  return (
    <>
      {popup? 
      <PopUp variables={variables} typePopup={typePopup} link={link} /> : 
      (<>
        <div className='container'>
          <div className='section-top'>
            <div className='section'>
              <div className='profile-img'>
                <div className='online'>{variables.online} 🟢</div>
                <img src={imageExists ? `/img/women/${afterSlash}.jpg` : `/img/women/anna.jpg`}/>
              </div>
              <div className='profile-name'>{afterSlash ? afterSlash : 'Anna' }</div>
              <div className='contact-info'>{variables.contact}</div>
              <div className='contact'>
                <img onClick={() => handleClick('message')} src='/img/telegram.png'/>
                <img onClick={() => handleClick('message')} src='/img/whatsup.png'/>
                <img onClick={() => handleClick('message')} src='/img/message.png'/>
              </div>
            </div>
            <div className='section' style={{padding: '1em', background: '#ffffff36'}}>
              <div className='title'>{variables.title}</div>
              <div className='subtitle'>{variables.subtitle}</div>
              <div onClick={() => handleClick('login')} className='btn'>{variables.login}</div>
            </div>
          </div>
          <div className='section' style={{flexDirection: 'row', marginTop: '2vh'}}>
            <div className={`btn ${typePopup === 'pics' ? 'active' : 'inactive'}`} style={{ minWidth: 'fit-content' }} onClick={() => setTypePopup('pics')}>{variables.pics} ({picsCount})</div>
            <div className={`btn ${typePopup === 'films' ? 'active' : 'inactive'}`} style={{ minWidth: 'fit-content' }} onClick={() => setTypePopup('films')}>{variables.films} ({filmsCount})</div>
          </div>
          <div className='section' style={{flexDirection: 'row', marginTop: '2vh', flexWrap: 'wrap'}}>
            <ImageGen typePopup={typePopup} variables={variables} picsCount={picsCount} filmsCount={filmsCount} handleClick={handleClick}/>
          </div>
        </div>
      </>)
}
    </>
  );
}

export default App;
